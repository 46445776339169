<template>
    <div class="PriceCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="batchChangeCustomBar"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="handleSave" size="small" :disabled="disableSaveButton"
                    >保存
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="250">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品条码" width="220" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="助记码" width="220" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{ scope.row.fastBar }}
                        </template>
                    </el-table-column>
                    <el-table-column label="自编码" width="200">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.customBar'"
                                :rules="rules.customBar"
                            >
                                <el-input v-model="scope.row.customBar" oninput="value=value.replace(/[^\d]/g,'')" />
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" @queryGoods="setSelectedGoods" />
    </div>
</template>

<script>
import PickUpGoods from 'components/PickUpGoods';
import StockBizCommon from 'js/StockBizCommon';
import { goodsCodeValidateRule } from 'js/validate/ValidateCommonRule';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'ChangeCustomBar',
    components: { PickUpGoods },
    props: {
        deptLabel: {
            type: String,
            default: '机构名称',
        },
    },
    data() {
        return {
            //有初始机构
            haveInitDept: false,
            routerName: 'menu.goods.price.batchChangeCustomBar',
            search: '',
            dialogVisible: false,
            form: {
                detailParams: [],
            },
            goodsDetailMap: new Map(),
            rules: {
                goodsCode: goodsCodeValidateRule(),
                customBar: [
                    {
                        required: false,
                        message: '请输入正确的自定义编码',
                        trigger: 'blur',
                        pattern: window.ef.someRegs.customBar,
                    },
                ],
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableSaveButton() {
            return this.form.detailParams == null || this.form.detailParams.length === 0;
        },
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            if (!this.haveInitDept) {
                //清空商品列表数据
                this.form.detailParams = [];
            } else {
                this.haveInitDept = false;
            }
        },
        $route: {
            immediate: true,
            handler: function (to, from) {
                if (to.name === this.routerName) {
                    this.initGoodsSelectData(to.params);
                }
            },
        },
    },
    methods: {
        initGoodsSelectData(params) {
            this.$nextTick(() => {
                if (Object.keys(params).length > 0) {
                    this.haveInitDept = true;
                    this.setSelectedGoods(params.goodsPageSelect);
                } else {
                    this.haveInitDept = false;
                }
            });
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setSelectedGoods(selectedGoods) {
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                this.form.detailParams,
                this.detailParamPropertyArrForAdd()
            );
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        handleSave() {
            if (this.form.detailParams != null || this.form.detailParams.length !== 0) {
                this.$refs['batchChangeCustomBar'].validate((valid) => {
                    if (!valid) {
                        return;
                    }
                    const form_ = [];
                    for (const item of this.form.detailParams) {
                        form_.push({ goodsCode: item.goodsCode, customBar: item.customBar });
                    }
                    UrlUtils.PostPriceBatchRemote(
                        this,
                        '/goods/goods/batchChangeCustomBar',
                        form_,
                        { timeout: 3 * 60 * 1000 },
                        () => {
                            this.goBackAndReload();
                            this.$message.success('修改自编码成功');
                        }
                    );
                });
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        detailParamPropertyArrForAdd() {
            return ['goodsCode', 'sku', 'customBar', 'fastBar'];
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
